import {Inject, Injectable, isDevMode} from '@angular/core';

import {WINDOW} from '../../tokens';

@Injectable()
export class SessionStorageService {
  private readonly sessionStorage: Storage;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
  ) {
    this.sessionStorage = window.sessionStorage;
  }

  getItem<T>(key: string): T | null | undefined {
    try {
      const item = this.sessionStorage.getItem(key);
      return item ? JSON.parse(item) as T : null;
    } catch (error) {
      this.logError(error);
      return null;
    }
  }

  setItem<T>(key: string, value: T): void {
    try {
      this.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      this.logError(error);
    }
  }

  removeItem(key: string): void {
    try {
      this.sessionStorage.removeItem(key);
    } catch (error) {
      this.logError(error);
    }
  }

  clearStorage(): void {
    try {
      this.sessionStorage.clear();
    } catch (error) {
      this.logError(error);
    }
  }

  private logError(error: string): void {
    if (isDevMode()) {
      console.error(error);
    }
  }
}
