import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {SessionStorageService} from './session-storage.service';

import {WINDOW} from '../../tokens';

export function sessionStorageServiceFactory(window: Window): SessionStorageService {
  return new SessionStorageService(window);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
})
export class SessionStorageModule {
  constructor(@Optional() @SkipSelf() parentModule: SessionStorageModule) {
    if (parentModule) {
      throw new Error('SessionStorageModule module should be imported only in CoreModule.');
    }
  }

  static forRoot(): ModuleWithProviders<SessionStorageModule> {
    return {
      ngModule: SessionStorageModule,
      providers: [
        {
          provide: SessionStorageService,
          useFactory: sessionStorageServiceFactory,
          deps: [WINDOW],
        },
      ],
    };
  }
}
